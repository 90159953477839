<template>
  <div>
     <b-modal
     
      id="modal-recentSearch"
      size="md"
      :ok-title="countDown"
       centered
       ok-only
       hide-footer="true"
       no-close-on-backdrop
       title="Recent Searches"
    >
<b-row>
<b-col>
                 <b-form-input
                v-model="recentSearch"
                class="d-inline-block "
                placeholder="Type and press enter..."
                @change="RecentSearch()"
              />

</b-col>
</b-row>
 <div v-if="searchLoading == true">

    <b-spinner variant="primary" type="grow" style="margin-left:200px;" class="mt-2 p-2"  label="Spinning"></b-spinner>
      </div>
<h4 class="mt-1 mb-1"> {{ recentText }}</h4>

      <b-table
      ref="selectableTable"
      :items="searchRows"
      :fields="searchColumns"
      responsive
      hover
       @row-clicked="myRowClickHandler"
        show-empty
      
    >
      <template #empty="scope">
      <h4 class="text-center">{{ scope.emptyText }}</h4>
    </template>
      <template #cell(coverImg)="data">
      <b-img  :src="data.value" style="width: 75px; height: 75px" rounded alt="Rounded image"/>
      </template>
      <template #cell(title)="data">
         <span> <strong> {{data.item.title}} </strong> </span>
        </template>
          <!-- <template #cell(price)="data">
         <span> <strong>  AED {{data.item.price}} </strong> </span>
        </template> -->
      <!-- Example scoped slot for select state illustrative purposes -->
      <!-- <template  #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template> -->


        
      

          <!-- <template #cell(actions)="data">
              

              </template> -->
       <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
    </b-table>

    </b-modal>
    <b-row>
      <b-col cols="12" lg="8" md="7" sm="12" order="3">
          <b-modal
      id="modal-login"
     
      ok-title="Load"
      centered
       ok-only
       hide-footer="true"
       no-close-on-backdrop
      
      title="Load Keywords"
    >
      <b-form>
    
    
     
        <b-form-group >
          <b-row class="mt-2">
            <b-col cols="3">
           <h5 style="margin-top:10px;"> Keywords </h5>

                       
            </b-col>
              <b-col>
              <v-select
                      v-model="selectedKeywords"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="keywordsOptions"
                      :clearable="false"
                      ref="status"
                      label="title"
                      :reduce="val => val.title"
                      :disabled="dataloading"
                      placeholder="Select status"
                    />
                      <small class="text-danger" v-if="this.errors.status == true">Please select status.</small>
              </b-col>
              
          </b-row>
           
        </b-form-group>


       
      </b-form>  
            <br>    
               <div style="" >
                <b-button
              class="float-right ml-5"
                  variant="primary"
                  @click="LoadKeywords()"
                >
                  
                  <span class="text-nowrap">Load</span>
                </b-button>
              </div>
            
            
          </b-modal>
           <b-modal
      id="modal-template"
      ok-title="Load"
      centered
       ok-only
       hide-footer="true"
      title="Save Template"
    >
      <b-form>
    
    
     
        <b-form-group >
          <b-row class="mt-2">
            <b-col cols="4">
           <h5 style="margin-top:10px;"> Template Name </h5>

                       
            </b-col>
                 <b-col>
              <b-form-input
                     v-model="keywordObj.title"
                      placeholder="Enter template name."
                      @focusout="checkTempTitle()"
                      
                      ref="templateName"
                     
                    />
              </b-col>   
              
          </b-row>
           
        </b-form-group>


       
      </b-form>  
            <br>    
               <div style="" >
                <b-button
                 class="float-right ml-5"
                  variant="primary"
                  @click="SaveTemplate()"
                >
                  
                  <span class="text-nowrap">Save</span>
                </b-button>
              </div>
            
            
    </b-modal>
        <b-card>
          <!-- media -->
          <b-media no-body>
            <!--         
        <b-media-aside>
              <b-img
                ref="previewEl"
                rounded
                 :src="require('@/assets/images/elements/Untitled-3 copy.png')" 
                 v-if="files[0] == null"
                height="120"
                width="120"
              />
          </b-media-aside>  -->

            <div v-if="loading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="require('@/assets/images/elements/Untitled-3 copy.png')"
                  @click="$refs.file2.click()"
                  v-if="loading == false"
                  height="120"
                  width="120"
                />

                <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
              </b-link>

              <b-link v-if="loading == 'loaded'">
                <div>
                  <i
                    @click="deleteCover()"
                    class="fa fa-trash fa-sm  position-absolute m-5"
                  ></i>
                </div>
                <b-img rounded :src="getImageUrl()" height="120" width="120" />
                <br />
                <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
              </b-link>

              <input
                type="file"
                id="file2"
                hidden
                ref="file2"
                @change="selectFile()"
                accept="image/*"
              />
            </b-media-aside>
            <b-card-text class="mt-5"
              >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
            >
            <b-media-body class="mt-75 ml-75"> </b-media-body>
          </b-media>
          <!--/ media -->
          <!-- form -->
          <b-form class="mt-2">
           <b-row>
          <b-col>
           <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class=" btn-icon"
              style="margin-top:27px;"
              :disabled="dataloading" 
              v-b-modal.modal-recentSearch
              @click="LoadSearches()"
              
            >
                  <feather-icon icon="SearchIcon" />
                  Recent Searches 
              </b-button>
          </b-col>             
           </b-row>
              <!-- <b-row>
              <b-col sm="12">
                <b-form-group label="Keywords" >
                  <textarea
                    class="form-control"
                    v-model="myObj.keywords"
                    name="keywords"
                    placeholder="Enter cake keywords here."
                   
                    ref="keywords"
                    rows="3"
                  ></textarea>

                  <small
                    class="text-danger"
                    v-if="this.errors.keywords == true"
                    >Please enter at least one keyword.</small
                  >
                </b-form-group>
              </b-col>
              </b-row> -->
   
               
              <!-- <b-row>
                <b-col lg="3" md="6" sm="6" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class=" "
                  :disabled="this.saveButton"
                  @click="LoadKeywordsModal()"
                >
                  Load Keyword
                </b-button>

                </b-col>

                 <b-col lg="3" md="6" sm="6" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-md-0 mt-sm-0 mt-2 "
                  :disabled="this.saveButton"
                  @click="SaveTemplateModal()"
                >
                  Save Template
                </b-button>

                </b-col>

              </b-row> -->
               <div class="mt-1">
             <b-row >
               <b-col sm="4">
                <!-- <div >
              {{username.username}}
              </div>    -->
                <b-form-group label="SKU no">
                  <b-form-input
                    v-model="myObj.skuNo"
                    placeholder="Enter SKU number"
                    @focusout="checkSKU()"
                  
                  />
                  <small class="text-danger" v-if="this.errors.skuNo == true"
                    >Please enter sku number.</small
                  >
                  
                </b-form-group>
              
              </b-col>
               <!-- <b-col sm="1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class=" btn-icon"
              style="margin-top:27px;"
              @click="LoadBySKU()"
            >
                  <feather-icon icon="SearchIcon" />
              </b-button>
                </b-col> -->
               <b-col sm="4" >
                <!-- <div >
              {{username.username}}
              </div>    -->
                <b-form-group label="Name">
                  <b-form-input
                    v-model="myObj.title"
                    placeholder="Enter product name"
                    @focusout="checkTitle()"
                    id="product-add"
                    ref="title"
                  />
                  <small class="text-danger" v-if="this.errors.title == true"
                    >Please enter cake name.</small
                  >
                </b-form-group>
              </b-col>

                   <b-col sm="4">
             <!-- <treeselect v-model="value" :multiple="false" :options="options"  /> -->
                <b-form-group
                  label="Category"
                >
                  <v-select
                    v-model="myObj.categoryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.categoryId"
                    :options="categoryOptions"
                    label="Name"
                    :clearable="false"
                    input-id="categoryId"
                    @input="SubCategory(myObj.categoryId)"
                    ref="category"
                    :disabled="dataloading"
                    placeholder="Select category"
                  />
                  <small class="text-danger" v-if="this.errors.category == true"
                    >Please select category.</small
                  >
                </b-form-group>
              </b-col>

              </b-row>
              <b-row v-if="selectedSubCategory.length !== 0">
              <b-col lg="8" md="6" sm="6"></b-col>
              <b-col lg="4" md="6" sm="6">
                 <b-form-group
                  label="Sub Category"
                >
                  <v-select
                    v-model="myObj.subCategoryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.subCategoryId"
                    :options="selectedSubCategory"
                    label="name"
                    :clearable="false"
                    input-id="subCategoryId"
                   
                    ref="subCategoryId"
                    :disabled="dataloading"
                    placeholder="Select sub category"
                  />
                
                </b-form-group>
              </b-col>
              </b-row>

             <b-row>
      <b-col sm="4">
                <b-form-group label="Flavour">
                  <v-select
                    v-model="flvObj.cakeFlavours"
                    :closeOnSelect="false"
                    :multiple="true"
                    :disabled="dataloading"
                    :options="flavorOptions"
                    label="Name"
                    :reduce="val => val.flavourId"
                    :clearable="true"
                    input-id="flavourId"
                    placeholder="Select flavour"
                    required
                    ref="flavour"
                  />
                  <small class="text-danger" v-if="this.errors.flavour == true"
                    >Please select flavour.</small
                  >
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                  label="Filling"
                  
                >
                  <v-select
                    v-model="myObj.fillingName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.name"
                    :options="fillingOptions"
                    label="name"
                    :clearable="false"
                    input-id="fillingName"
                    ref="filling"
                    :disabled="dataloading"
                    placeholder="Select filling"
                  />
                  <small class="text-danger" v-if="this.errors.filling == true"
                    >Please select filling.</small
                  >
                </b-form-group>
              </b-col>
                    <b-col sm="4">
                <!-- <div >
              {{username.username}}
              </div>    -->
                <b-form-group label="Minimum days required">
                  <b-form-input
                    v-model="myObj.minimumTime"
                    placeholder="Enter minimum days"
                    @focusout="checkMinimumDays()"
                    id="product-add"
                    ref="minimumDays"
                  />
                  <small
                    class="text-danger"
                    v-if="this.errors.minimumDays == true"
                    >Please enter minimum days.</small
                  >
                </b-form-group>
              </b-col>
             </b-row>
              <b-row>       
       <b-col sm="8">
                <b-form-group label="Description" >
                  <textarea
                    class="form-control"
                    v-model="myObj.description"
                    name="description"
                    placeholder="Enter cake description here."
                    @focusout="checkDesc()"
                    ref="description"
                    rows="2 "
                  ></textarea>

                  <small
                    class="text-danger"
                    v-if="this.errors.description == true"
                    >Please enter cake description.</small
                  >
                </b-form-group>
              </b-col>

            <b-col sm="4">
                <!-- <div >
              {{username.username}}
              </div>    -->
                <b-form-group label="Total layers">
                  <b-form-input
                    v-model="myObj.totalLayer"
                    placeholder="Enter total no of layers"
                    @focusout="checktotalLayers()"
                    id="product-add"
                    ref="totalLayers"
                  />
                  <small
                    class="text-danger"
                    v-if="this.errors.totalLayers == true"
                    >Please enter total no of layers.</small
                  >
                </b-form-group>
              </b-col>
              </b-row>
              <b-row v-if="myObj.totalLayer > 1">
  <b-col sm="12" v-if="myObj.totalLayer > 1">
              <b-form
              ref="form"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in cakeLayers"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col lg="3" md="6">
                  <b-form-group label="Dummy layer" label-for="item-name">
                    <b-form-input
                      id="name"
                      type="number"
                      v-model="item.dumyLayer"
                      placeholder="Enter dummy layers"
                      :ref="item.id"
                      @keyup="MiniusCakeLayer(index)"
                    />
                    <small class="text-danger" v-if="errors.pname == true"
                      >Please enter partner name.</small
                    >
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col lg="3" md="6">
                  <b-form-group label="Cake layer" label-for="email">
                    <b-form-input
                      id="Email"
                      type="number"
                      v-model="item.cakeLayer1"
                      ref="pemail"
                      disabled
                      placeholder="Enter cakelayers"
                    />
                    <small class="text-danger" v-if="errors.pemail == true"
                      >Please enter partner email.</small
                    >
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col  lg="3" md="12">
                  <b-form-group label="Layer price" label-for="IdCard">
                    <b-form-input
                      id="EmiratesId"
                      type="number"
                      v-model="item.price"
                      ref="emiratesId"
                     
                      placeholder="Enter price"
                    />
                    <small class="text-danger" v-if="errors.emiratesId == true"
                      >Please enter partner emirates id.</small
                    >
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col lg="1" md="6"  >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    
                    v-if="index > 0"
                    @click="removeItem(index)"
                  >
                  <div>
                    <feather-icon icon="XIcon"  style="font-size:100px;"/>
                    
                 </div>

                  </b-button>
                </b-col>
                <b-col lg="1" md="6">
           <b-button
            v-show="index == 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
             class="mt-0 mt-md-2"
            @click="repeateAgain(index)"
          >  
            <feather-icon icon="PlusIcon"/>
           
          </b-button>
<!-- 
           <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
             class="mt-0 mt-md-2"
            @click="repeateAgain(index)"
          >  
            <feather-icon icon="PlusIcon"/>
           
          </b-button> -->
    
                </b-col>
            
              </b-row>
            </b-form>
              <b-col cols="12">
                  <hr />
                  <small class="text-danger"  v-if="this.errors.layers == true" 
                    >Please enter dummy layers and layer price.</small
                  >
                 
                </b-col>
      
              </b-col> 
              </b-row>
        
                
              <b-row>

      <b-col sm="6">
                <b-form-group
                  label="Sizes (Servings)"
                 
                  style="margin-top:7px;"
                >
                  <v-select
                    @input="openModal()"
                    v-model="sizes.size"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.name"
                    :options="sizeOptions"
                    label="name"
                    :clearable="false"
                    :disabled="dataloading"
                    input-id="sizeId"
                    :closeOnSelect="false"
                    ref="category"
                    placeholder="Select Sizes"
                  />
                  <small class="text-danger" v-if="this.errors.sizes == true"
                    >Please select sizes.</small
                  >
                </b-form-group>
              </b-col>
              </b-row>
         
             
          
       
        
              <b-row>
              <b-col sm="12">
                          <label
                            v-if="costVisible == true"
                            style="margin-left:0px;font-size:15px;"
                            >Cost</label
                          >
                        </b-col>
              </b-row>
             <b-container fluid>
  <b-row class="mr-5">
                          <b-col
                              lg="auto"
                              md="auto"
                              sm="auto"  
                              cols="auto"
                              class="mt-1"
                              v-for="(mysize, index) in this.cakesizeList"
                              :key="mysize.id"
                            >
                              <b-badge style="background-color:#FF5A60; padding:10px; ">
                                {{
                                  'Serving : ' +
                                    mysize.size +
                                    ' ' +
                                    'Cost :' +
                                    ' ' + 
                                    'AED ' +
                                    mysize.cost 
                                    
                                }}
                                <i
                                  class="fa fa-trash fa-sm ml-lg-1 ml-md-1 ml-sm-1 ml-0 "
                                  @click="removeCost(index)"
                                ></i>
                              </b-badge>
                            </b-col>

              </b-row>
            </b-container>
              
              <b-row>
                  <b-col cols="12">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="mt-2  float-right"
                              :disabled="this.saveButton"
                              @click="AddProduct()"
                            >
                              {{ addOptions }}
                            </b-button>
                          </b-col>
              </b-row>
               </div>
            
           
           
          </b-form>
        </b-card>
      </b-col>

      <b-col cols="12" lg="4" md="5" sm="12" order="3">
        <b-card>
          <h5 class="text-nowrap">
            More Images
          </h5>

          <b-row>
            <div v-if="moreloading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-link class="p-1">
              <b-img
                ref="previewEl"
                rounded
                :src="require('@/assets/images/elements/Untitled-3 copy.png')"
                @click="$refs.mfile.click()"
                v-if="moreloading == false"
                height="120"
                width="120"
              />

              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>

            <input
              type="file"
              id="mfile"
              hidden
              ref="mfile"
              multiple="multiple"
              @change="selectMoreFile()"
              accept="image/*"
            />

            <b-link v-for="img in proImg.images" :key="img.id">
              <div>
                <i
                  class="fa fa-trash fa-sm  position-absolute m-5"
                  @click="removeImg(index)"
                ></i>
              </div>

              <div >
              <b-img rounded :src="img" height="120" width="120" /> 
                
               </div>
              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>
          </b-row>
              <b-row class="mt-2">
              <b-col sm="12">
                <b-form-group label="Keywords" >
                  <textarea
                    class="form-control"
                    v-model="myObj.keywords"
                    name="keywords"
                    placeholder="Enter cake keywords here."
                   
                    ref="keywords"
                    rows="3"
                  ></textarea>

                  <small
                    class="text-danger"
                    v-if="this.errors.keywords == true"
                    >Please enter at least one keyword.</small
                  >
                </b-form-group>
              </b-col>
              </b-row>
                  <b-row>
                <b-col lg="5" md="6" sm="6" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class=" "
                  :disabled="this.saveButton"
                  @click="LoadKeywordsModal()"
                >
                  Load Keyword
                </b-button>

                </b-col>

                 <b-col lg="5" md="6" sm="6" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-md-0 mt-sm-0 mt-2 "
                  :disabled="this.saveButton"
                  @click="SaveTemplateModal()"
                >
                  Save Template
                </b-button>

                </b-col>
 
              </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BTable,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormInvalidFeedback,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
// import { required, alphaNum, email } from '@validations'
import Vue from 'vue';
import store from '@/store';
import productStoreModule from '../productStoreModule';
import axios from 'axios';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { required, minLength, between } from 'vuelidate/lib/validators';
import fs from 'fs';
import qs from 'qs';

export default {
  components: {
    BBadge,
    BButton,
    BForm,
    BImg,
    //BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTable,
    
    //BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    BSpinner,
    //Treeselect ,

    //mixins: [validationMixin],

    //FeatherIcon,
    //BFormInvalidFeedback,
    //FormValidation
    //ValidationProvider,
    // ValidationObserver,
  },
  created() {
       

    this.myObj.vendorId = 0;
 console.log( this.$store.state.userData.logo);
    this.LoadData();
  },
  directives: {
    Ripple,
  },
  // props: {
  //    priceCard: {
  //     type: Boolean,
  //     required: true,
  //   },

  // },
  // props:['username'],
  mounted() {},
  data() {
    return {
      searchLoading:false,
      recentText:'Recent Searches',
      recentSearchObj:
      {
      recentSearchId:0,        
      productId:0,
      vendorId:0,
      date:''
      },
      searchColumns:[
        {
          label: '',
          key: 'coverImg',
        },
        {
          label: '',
          key: 'title',
        },
       
        ],
      searchRows:[],
      Id:0,
      pemail:
      {
        person:'',
        bakery:'',
        email:'',
        productTitle:'',
        productURL:''
      },
      lastIndex:0,
        value: null,
        // define options
      subCategoryOptions:[],
      selectedSubCategory:[],
      keywordObj:
      {
      title:'',
      keywords:'',
      },
      selectedKeywords:'',
      keywordsOptions:[],
      calculation:0,
      saveButton: false,
      GenObj: {
        product: {},
        cakeSizes: [],
        cakeFlavours: {},
        cakeImages: {},
        cakeLayers:{}
      },
      cover:
        'https://appick.io/u/lessons/713e7b46-9d91-462d-a315-b77fafa2a72e.png',
      errors: {
        category: false,
        flavour: false,
        sizes: false,
        filling: false,
        title: false,
        minimumDays: false,
        totalLayers:false,
        description: false,
        layers:false,
        templateName:false,
        keywords:false,
        skuNo:false

      },
      recentSearch:'',
      saveCover:"",
      userVendorId: this.$store.state.userData.userID,
      moreloading: false,
      moreFiles: [],
      addOptions: 'Add Cake',
      count: 0,
      costVisible: false,
      feedback: false,
      sizeOptions: [],
      imageUrl: 'https://upload.appick.io',
      fileList: undefined,
      removecostId: 0,
      sizeId: 0,
      spin: '',
      files: undefined,
      cost: '',
      file: ' ',
      name: ' ',
      category: '',
      flvObj: {
        cakeFlavourId: [],
        cakeFlavours: [],
        pId: 0,
      },
      originalSizearr: [],
      size: 0.0,
      loading: false,
      dataloading: false,
      selectedFiles: undefined,
      selectedFlavours: [],

      cakeFlavours: [],
   cakeLayers: [
        {
          cakeLayerId:0,
          dumyLayer: 0,
          cakeLayer1: 0,
          price:0,
          productId: 0,
          vendorId: 0,
        },
      ],
      nextTodoId: 2,
      sizeObj: {
        size: '',
        cost: '',
      },
      // imgObj:{
      // pId: 0,
      // cakeFlavours: [],
      // },
      categoryOptions: [],
      getproductId: 0,
      clickedItems: [
        //   {
        //   id: '1', weight: '6 lb', size: '11"', serving: '6', price: 'AED 23.4',
        // },
        // {
        //   id: '2', weight: '7 lb', size: '12"', serving: '7', price: 'AED 24.4',
        // },
        // {
        //   id: '3', weight: '8 lb', size: '13"', serving: '8', price: 'AED 25.4',
        // },
      ],
      moreImages: [
        // { img: require('@/assets/images/elements/Unicorn-Cake.jpg')},
        // { img: "https://handletheheat.com/wp-content/uploads/2015/03/Best-Birthday-Cake-with-milk-chocolate-buttercream-SQUARE.jpg"},
        // { img: require('@/assets/images/elements/Unicorn-Cake.jpg')},
        // { img: "https://handletheheat.com/wp-content/uploads/2015/03/Best-Birthday-Cake-with-milk-chocolate-buttercream-SQUARE.jpg"},
        // { img: require('@/assets/images/elements/Unicorn-Cake.jpg')},
        // { img: require('@/assets/images/elements/Unicorn-Cake.jpg')},
      ],
      productResponse: {
        // Id : 0,
        // data:'',
        // status:''
      },
      //    categoryOptions : [

      //    {categoryId: null , Name: null , productId:null,Image:null}
      //   // { label: 'Wedding Cake', value: 'wedding' },
      //   // { label: 'Party Cake', value: 'party' },
      // ],

      flavorOptions: [
        // { label: 'All', value: 'all' },
        // { label: 'Chocolate', value: 'Chocolate' },
        // { label: 'Oreo', value: 'oreo' },
        // { label: 'Cheese', value: 'cheese' },
        // { label: 'Banana', value: 'banana' },
      ],

      fillingOptions: [],
      countDown : 10,
      myObj: {
        productId: this.$store.state.userData.productId,
        title: '',
        price: 0,
        categoryId: 0,
        description: '',
        vendorId: 0,
        approvedOn: null,
        isApproved: '',
        coverImg: '',
        minimumTime: 0,
        perLayerCost: 0,
        fillingName: '',
        totalLayer:0,
        keywords:'',
        subCategoryId:0,
        skuNo:"",

      },
      // images:[],
      // flavourId:[],
      cakesizeList: [],
      sizes: {
        productId: 0,
        size: 'Select size',
        cost: '',
      },
      proImg: {
        pId: 0,
        images: [],
      },

      // //      profileData: { },
      //       items: [
      //         {
      //           weight: '6 lb', size: '11"', serving: '6', price: 'AED 23.4',
      //         },
      //         {
      //           weight: '6 lb', size: '11"', serving: '6', price: 'AED 23.4',
      //         },
      //         {
      //           weight: '6 lb', size: '11"', serving: '6', price: 'AED 23.4',
      //         },
      //         {
      //           weight: '6 lb', size: '11"', serving: '6', price: 'AED 23.4',
      //         },
      //       ],

      //priceTableShow: true
    };
  },
computed:
{

},
  methods: 
  {
    LoadSearches()
    {
        this.recentSearch = '';
        this.recentText = 'Recent Searches'
        this.searchLoading = true;

 var configs = {
          method: 'get',
          headers: {
            Authorization:
              'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
          },
          url: 'https://okaaik.fastech.pk/api/products/GetRecentSearch/' + this.$store.state.userData.userID,
        };

        axios(configs)
          .then(response => {
             console.log(response.data);
           if(response.data.status == "success")
           {
             
             this.searchRows = response.data.data;
             this.searchLoading = false;

           }
          })
          .catch(function(error) {
            console.log(error);
            this.searchLoading = false;

            //this.dataloading = true;
          });

    },
myRowClickHandler(items,index) 
       {
     // this.selected = items
    this.recentSearchObj.productId = items.productId;
    this.recentSearchObj.vendorId = this.$store.state.userData.userID;
     
      console.log(this.recentSearchObj);
      console.log(items.productId);
          var configs = {
          method: 'post',
          headers: {
            Authorization:
              'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
          },
          url: 'https://okaaik.fastech.pk/api/products/AddRecentSearch' ,

          data: this.recentSearchObj,
        };

        axios(configs)
          .then(response => {
            // console.log(JSON.stringify(response.data));
            console.log(response.data.data[0]);
            this.myObj = response.data.data[0];
            console.log("------------",this.myObj.subCategoryId);
            this.SubCategory(this.myObj.categoryId);
            
            console.log(this.myObj);
            this.loading = 'loaded';
            console.log(response.data.flavours);
            this.flvObj.pId = 0;
            this.flvObj.cakeFlavourId = [];
            this.flvObj.cakeFlavours = [];

            response.data.flavours.forEach(elem => {
              this.flvObj.pId = elem.productId;
              this.flvObj.cakeFlavourId.push(elem.cakeFlavourId);
              this.flvObj.cakeFlavours.push(elem.flavourId);
            });
            console.log(response.data.cakesize);
            //this.cakesizeList.push(response.data.cakesize);
            console.log('Osama', response.data.cakesize);
            this.cakeLayers = [];
            if(response.data.cakelayer.length > 0)
            {
            this.cakeLayers = response.data.cakelayer
            }
            this.cakesizeList = [];
            response.data.cakesize.forEach(elem => {
              this.cakesizeList.push({
                cakeSizeId: elem.cakeSizesId,
                productId: elem.productId,
                cost: elem.cost,
                size: elem.size,
              });
            });
          // console.log("merilayers", this.cakeLayers);
            this.proImg.images = [];
            this.proImg.pId = 0;
            response.data.images.forEach(elem => {
              this.proImg.images.push(elem.imagePath);
              this.proImg.pId = elem.productId;
            });
            console.log('All Data', response.data);
            this.$bvModal.hide("modal-recentSearch");
          })
          .catch(function(error) {
            console.log(error);
            //this.dataloading = true;
          });

    
    },
// update(){
//       this.$forceUpdate();
//     },
      countDownTimer() {
                if(this.countDown > 0) 
                {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
          
                }
        },
  RecentSearch()
{
//return console.log( this.$store.state.userData.userID);
 var axios = require('axios');
  this.searchLoading = true;

      var config = {
        method: 'get',
        url: 
        'https://okaaik.fastech.pk/api/products/VendorSearch/?text='+ this.recentSearch + "&id=" + this.$store.state.userData.userID,
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
      
      };

      axios(config)
        .then(response => {
          //  console.log(JSON.stringify(response.data));
          console.log(response.data);
          this.recentText = '';
          this.searchRows = []
          this.searchRows = response.data.data;
         // this.dataloading = false;
        this.searchLoading = false;

          
          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
         this.searchLoading = false;
 
        //  this.dataloading = true;
        });
  // this.countDownTimer();
  // if(this.countDown == 0)
  // {
  //   //console.log("Hello Osama times up");
  //   //this.countDown = 10;
  // }


},
SubCategory(id)
{
 console.log(id);
console.log(this.subCategoryOptions); 
this.selectedSubCategory = [];
this.subCategoryOptions.forEach(elem=>
{

  if(elem.categoryId == id)
  {
    this.selectedSubCategory.push(elem)
  }
  else
  {
  this.selectedSubCategor = []

  }
})
console.log(this.selectedSubCategory);
},
    SaveTemplateModal()
    {
      console.log();
      this.checkKeywords();
      if(this.checkKeywords() == false  )
      {

      }
      else
      {
      this.$bvModal.show("modal-template");

      }
 
    },
     SaveTemplate()
     {
       this.checkTempTitle();
       this.keywordObj.keywords = this.myObj.keywords;
            if(this.checkTempTitle() == false )
    {
          return  this.$bvToast.toast('Please fill the form correctly.', {
            title: "Error!",
            variant: 'danger',
            toaster:  'b-toaster-bottom-center',
      })
    }
    else
    {
     //console.log(this.myObj);
    if(this.keywordObj.title !== '')
    {
       console.log(this.keywordObj);
    var axios = require('axios');
    // this.$store.state.userData.userID
      var config = {
        method: 'post',
        url: 'https://okaaik.fastech.pk/api/keywordTemplates/Post/',
        headers: {
          'Authorization': 'bearer T6O1i268ccP1C_94E49EDA_4sATCbEdIPthcLv1QyEVpyyvnTBzJ-6c79dAr1M9IeDV5gcBQhluDPPxmKok0XNUf5S5nM4jPvUGlyoeglhPvGKjJNaLSe6Kz2kLwUn0VML_mLWcHBtz5cBWqpipD_TiMT1_bEFG-p9i4gswBn0GIrKtX4QMSSa9O82E5qrKwOcEV2hR4Y-FJADFticsWbP2wrQQfK6EPbc1Y8GTwBchu7-HrMCHyJNO06BpWoUB2lUkGktZuqsmEeRSgrDc8fAuQjbqpwUmAD_KYCVwK7avahTN6MVGIVCgu5u54cG-4'
        },
        data : this.keywordObj
      };

      axios(config)
      .then( (response) => {
        if(response.data.status === "success")
        {
        console.log(response.data);
         this.$bvModal.hide("modal-template");
       

            this.$bvToast.toast('Template saved.', {
            title: "Success!",
            variant: 'success',
            toaster:  'b-toaster-bottom-center',
         
          
      })
        }
        else if(response.data.status === "exist")
        {
         return  this.$bvToast.toast('This title is already saved please type another title.', {
            title: "Error!",
            variant: 'danger',
            toaster:  'b-toaster-bottom-center',
      })
        }
        
        //  this.data  =  response.data;
        //console.log(this.$store.state.userData.userID);
      //this.items = response.data;

       // console.log(this.items);
    })
      .catch(function (error) {
        console.log(error);
      });

    }
    }
     },
    LoadKeywords()
    {
    
    if(this.selectedKeywords == 0  || this.selectedKeywords == '')
    {
      return 0;
    } 
    else
    {
      //return  console.log("else",this.selectedKeywords);
        var axios = require('axios');
   
      var configselect = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/keywordTemplates/Get/?title='+this.selectedKeywords,
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
       
      };
       console.log(configselect.url);
      axios(configselect)
        .then(response => {
          //  console.log(JSON.stringify(response.data));
          if(response.data[0].title == this.selectedKeywords)
          {

           this.dataloading = false;
           this.myObj.keywords = response.data[0].keywords;

           this.$bvModal.hide("modal-login");
          }
          else
          {
          console.log("---",response.data);

          }
          //console.log("---",response.data);
        
          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });
    } 
    

    },
    LoadKeywordsModal()
    {
      this.$bvModal.show("modal-login");
    },
    MiniusCakeLayer(index)
    {
      console.log(index);
   
      console.log("First");
    const value = event.target.value;
    var mylayer = (this.myObj.totalLayer - value);
    this.cakeLayers[index].cakeLayer1 = mylayer;
    
    },

    // ClearCFlavourId() {
    //   console.log('HIOsama');
    // },
    removeImg(index) {
      console.log(index);
      this.proImg.images.splice(index, 1);
    },
    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append('file', this.$refs.mfile.files[i], '[PROXY]');
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then(response => response.json()) //2
          .then(result => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.proImg.images.push(fn);
            }
            console.log(this.proImg.images);
          })

          .catch(error => console.log('error', error));
      }

      // this.loading = true;
      //this.moreFiles = this.$refs.mfile.files;

      // console.log(this.moreFiles);

      //console.log(this.file, this.loading );
      //Upload cover

      //       if (this.moreFiles !== [])
      //       {

      //        let formData = new FormData();
      //       for( var i = 0; i < this.$refs.mfile.files.length; i++)
      //       {
      //       let file = this.$refs.mfile.files[i];
      //       console.log(file);
      //       formData.append('files[' + i + ']', file);
      //       }

      //     axios
      //    .post("https://upload.appick.io", formData,
      //    {
      //     headers: {
      //               "Content-Type": "multipart/form-data",
      //              },
      //             })
      //     .then((e) => {

      //  return console.log(e.data);

      //     for (var i = 0, len = e.data.myresp.length; i < len; ++i)
      //  {
      //    var fn = "https://appick.io/u/lessons/" + e.data.myresp[i].filename;
      //    this.moreImages.push(fn);

      //  }
      //     console.log(this.moreImages);

      //                   //console.log(this.myObj.coverImg);
      //                  // this.loading = 'loaded';
      //                   console.log(this.loading);
      //                 });
      //             }
    },
    addItem(productId) {
      for (var i = 0, len = this.cakesizeList.length; i < len; ++i) {
        this.cakesizeList[i].productId = productId;

        this.originalSizearr.push(this.cakesizeList[i]);
      }
    },
    // if(this.myObj.cakesizeList.length > 0)
    // {
    //   this.myObj.cakesizeList[0].productId = productId;
    //   this.originalSizearr.push(this.myObj.cakesizeList[0])
    //   this.myObj.cakesizeList.shift();
    // }
    //      if(this.count < this.myObj.cakesizeList.length)
    //      {
    //     this.myObj.cakesizeList[this.count].productId = productId;
    //     let addItem = this.myObj.cakesizeList.slice(this.count, this.count + 1);
    //     this.originalSizearr.push(addItem[this.count]);
    //     this.count++
    // } else {
    //    console.log('all items are added');
    //    return true;
    // }
    // },

    removeCost(index) {
      console.log(index);
      this.cakesizeList.splice(index, 1);
      this.costVisible = false;
    },
    openModal() {
      console.log(this.sizes);

      // var cost = this.cakeSize.cost
      Swal.fire({
        title: 'Enter Cake Cost',
        input: 'text',

        inputPlaceholder: 'Enter cake cost',
        confirmButtonText: 'Add',
        confirmButtonColor: '#FF5A60',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off',
        },
      }).then(result => {
        if (result.value) {
          this.costVisible = true;
          console.log('Result: ' + result.value);
          this.sizes.cost = result.value;

          // const p = this.sizes;
          this.cakesizeList.push({
            productId: this.sizes.productId,
            cost: this.sizes.cost,
            size: this.sizes.size,
          });
          console.log(this.cakesizeList);
        }
      });
    },
    checktotalLayers()
    {
      var elem = this.$refs['totalLayers'];
      var re = /^[+]?\d+([.]\d+)?$/;
      if (
        re.test(this.myObj.totalLayer) &&
        this.myObj.totalLayer !== '' &&
        this.myObj.totalLayer != 0
      ) {
        return   this.errors.totalLayers = false;
       
      } else {
      return  this.errors.totalLayers = true;

        
      }
    },
    checkMinimumDays() {
      var elem = this.$refs['minimumDays'];
      var re = /^[+]?\d+([.]\d+)?$/;
      if (
        re.test(this.myObj.minimumTime) &&
        this.myObj.minimumTime !== '' &&
        this.myObj.minimumTime != 0
      ) {
        return this.errors.minimumDays = false;
        
      } else {
        return this.errors.minimumDays = true;


      }
    },
    checkCategory() {
      if (this.myObj.categoryId == 0 || this.myObj.categoryId == null) {
        return (this.errors.category = true);
      } else {
        return (this.errors.category = false);
      }
    },
    checkFilling() {
      if (this.myObj.fillingName == '' || this.myObj.fillingName == null) {
        return (this.errors.filling = true);
      } else {
        return (this.errors.filling = false);
      }
    },
    checkKeywords()
    {
      var elem = this.$refs['keywords'];
      if (
        this.myObj.keywords !== undefined &&
        this.myObj.keywords.length > 2 &&
        this.myObj.keywords !== '' &&
        this.myObj.keywords !== 0
      ) {
        this.errors.keywords = false;
        return (elem.state = true);
      } else {
        this.errors.keywords = true;

        return (elem.state = false);
      }
    },
checkTempTitle()
{
    var elem = this.$refs['templateName'];
      if (
        this.keywordObj.title.length > 2 &&
        this.keywordObj.title !== '' &&
        this.keywordObj.title !== 0
      ) {
        this.errors.title = false;
        return (elem.state = true);
      } else {
        this.errors.title = true;

        return (elem.state = false);
      }
  
},
    checkTitle() {
      var elem = this.$refs['title'];
      if (
        this.myObj.title.length > 2 &&
        this.myObj.title !== '' &&
        this.myObj.title !== 0
      ) {
        return  this.errors.title = false;
        
      } else {
        return  this.errors.title = true;

        
      }
    },

    checkDesc() {
   
      if (
        this.myObj.description.length > 2 &&
        this.myObj.description !== '' &&
        this.myObj.description !== 0
      ) {
       return   this.errors.description = false;
       
      } else {
        return this.errors.description = true;        
      }
    },
    checkSKU() {
      if (
        this.myObj.skuNo !== "" && this.myObj.skuNo !== null       
      ) {
      return   this.errors.skuNo = false;
        
      } else {
      return   this.errors.skuNo = true;
      }
    },
    // checkCostperlayer() {
    //   var elem = this.$refs['Costperlayer'];
    //   if (this.myObj.perLayerCost <= 0) {
    //     elem.state = false;
    //     console.log(elem.state);
    //   } else {
    //     elem.state = true;
    //   }
    // },
    checkFlavour() {
      //console.log("myF",this.flvObj.cakeFlavours.length);
      if (this.flvObj.cakeFlavours.length === 0) {
        ///console.log("Osama");
        return (this.errors.flavour = true);
      } else {
        return (this.errors.flavour = false);
      }
    },
    checkSizes() {
      //console.log("myF",this.flvObj.cakeFlavours.length);
      if (this.cakesizeList.length === 0) {
        ///console.log("Osama");
        return (this.errors.sizes = true);
      } else {
        return (this.errors.sizes = false);
      }
    },
    deleteCover() {
      console.log(0);
      this.myObj.coverImg = '';
      this.loading = false;
    },
    // AddMoreImage()
    // {
    // //console.log(this.$refs.filee.files[0]);
    // this.fileList = this.$refs.filee.files[0]
    // console.log(this.fileList);
    //   if (this.fileList !== undefined) {
    //   // console.log(fileList);
    //       let formData = new FormData();
    //       for (let index = 0; index < this.fileList.length; index++) {

    //       const element = this.fileList[index];
    //         console.log("elem", element);
    //         formData.append("file", element);
    //       }

    //       axios
    //         .post("https://upload.appick.io", formData, {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           }.then((response)=>{

    //             console.log(response);
    //           })
    //         })
    //   }

    // },

    // submit() {
    //       const formData = new FormData();
    //       formData.append("file", fileList[0].blob);
    //       fetch
    //         .post("https://upload.appick.io", formData)
    //         .then((response) => {

    //           console.log(this.fileList[0]);
    //         })
    //         .catch(() => {});
    //     },

    getImageUrl() {
      return this.myObj.coverImg;
    },
    selectFile() {
      this.file = this.$refs.file2.files[0];

      console.log(this.file.name);

      console.log(this.file, this.loading);
      //Upload cover
      if (this.file !== '') {
        this.loading = true;

        let formData = new FormData();
        formData.append('file', this.file);
        console.log(this.file);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(e => {
            console.log(e.data);
            // this.file = undefined;
            var fn =  e.data.myresp[0].path;
            console.log(fn);
            this.myObj.coverImg = fn;
            //console.log(this.myObj.coverImg);
            this.loading = 'loaded';
            console.log(this.loading);
          });
      }
    },
        repeateAgain(index) {
        console.log(this.cakeLayers[index]); 
       if(this.cakeLayers[index].dumyLayer == 0 || this.cakeLayers[index].dumyLayer == undefined ||  
       this.cakeLayers[index].dumyLayer == "" || this.cakeLayers[index].price == 0 || this.cakeLayers[index].price == "" || this.cakeLayers[index].price == undefined)
        {
         
         return  this.errors.layers =true;

        }

        this.cakeLayers.push({
        // id: this.nextTodoId += this.nextTodoId,
         cakeLayerId:0,
         productId:0,
         vendorId:0,
         dumyLayer: this.cakeLayers.dumyLayer,
         cakeLayer1: this.cakeLayers.cakeLayer1,
         price: this.cakeLayers.price,
       
      });
      console.log(this.cakeLayers);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });

      //  console.log(this.vendorP);
      //  this.vendorObj.partners.push(this.vendorP);
      //  console.log(this.vendorObj.partners);
      //   this.vendorP.name = '' ;
      //   this.vendorP.email = '' ;
      //   this.vendorP.emiratesId = '' ;

      //  console.log(this.vendorP);
    },
    checkLayers()
    {
      
        this.cakeLayers.forEach(elem =>{
          if(elem.dumyLayer == 0 || elem.dumyLayer == undefined ||  
       elem.dumyLayer == "" || elem.price == 0 || elem.price == "" || elem.price == undefined)
        {
         
         return  this.errors.layers = true;

        }
        else
        {
          return  this.errors.layers = false;
          
        }
        })
    },
        removeItem(index) {
      this.cakeLayers.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    //  Addflavour()
    //  {
    //    console.log("Osama");
    //    console.log(this.flvObj.flavourId);
    //  },
    // moreImages()
    // {

    // },
    AddProduct() {
      //console.log(this.selectedFlavours)
      
   // return console.log(this.myObj);
      if(this.myObj.totalLayer > 1)
      {
        this.checkLayers();
      }
      this.checkSKU();
      this.checktotalLayers();
      this.checkTitle();
      this.checkDesc();
      this.checkCategory();
      this.checkFlavour();
      this.checkSizes();
      this.checkFilling();
      this.checkMinimumDays();
      //this.checkKeywords();
      if (
        this.checkTitle() == true ||
        this.checkDesc() == true ||
        this.checktotalLayers() == true ||
        this.checkCategory() == true ||
        this.checkFlavour() == true ||
        this.checkSizes() == true ||
        this.checkFilling() == true ||
        this.checkMinimumDays() == true ||
        this.checkLayers() == true ||
        this.checkSKU() == true
       // this.checkKeywords() == false
      ) {
        return this.$bvToast.toast('Please fill the form correctly.', {
          title: 'Error!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else {
        if (
          this.myObj.productId === 0 &&
          this.myObj.vendorId === this.userVendorId &&
          this.myObj.vendorId !== 0
        ) {
          this.saveButton = true;
          this.addOptions = 'Saving...';
          this.GenObj.product = this.myObj;
          this.GenObj.cakeFlavours = this.flvObj;
          this.GenObj.cakeImages = this.proImg;
          this.GenObj.cakeSizes = this.cakesizeList;
          this.GenObj.cakeLayers = this.cakeLayers;
         console.log('Gen', this.GenObj);

          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://okaaik.fastech.pk/api/products/AddProduct/',
            headers: {
              Authorization:
                'Bearer pKFBCiJ2J0r5BGU-mlOiSN_IWZF7m2Oxl1yv_PskYmT1udlK7seq3djoVgNfgDLJELFwwxdcYd9LQcvH9OVIpYyZTNrUPDuV9sAzC1WNz2SYEt2TIzJpWmzwAQcPbucnq-ALeFSQc6CsrxFhMacr3bc_KL0Cvl4diQUTvChDZVx4NPxZ-VtMXHiLMzvpBAcrxCQ2rF3V5TpBZFDXDBs1EIonYD9GCG9PHu6U-iIAXKAJ3Xi97-S2kqUd5SwXg0ANLKDqYiWHyF2OJev3_V4g48e9ApFAN4zm7nAPvv1vXZW5H-0vxXBZb1WulF-q5Ljp',
              'Content-Type': 'application/json',
            },
            data: this.GenObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status == 'success') {
                console.log(response.data);
                     this.pemail.bakery = this.$store.state.userData.bakery;
                    //this.pemail.email = this.$store.state.userData.email;
                    this.pemail.email = "osamausman1212@gmail.com";
                    this.pemail.person = this.$store.state.userData.username;
                    this.pemail.productTitle = this.myObj.title;
                    this.pemail.productURL = this.myObj.coverImg;   
                   console.log(this.pemail);     
                    var econfig = {
                      method: 'post',
                      url: 'https://mancave.appick.io/okaaikEmails/upload',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: this.pemail,
                    };

                    axios(econfig)
                      .then(response => {
                        console.log(JSON.stringify(response.data));
                      })
                      .catch(function(error) {
                        console.log(error);
                      });


                this.saveButton = false;
                this.addOptions = 'Add Cake';
                this.$router.push('/apps/products/list');
                this.$bvToast.toast('Cake Added.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
                // console.log();
              }
               else if (response.data.status == 'exist')
               {
                     this.saveButton = false;
                this.addOptions = 'Add Cake';
              this.$bvToast.toast('Please enter unique SKU number.', {
                  title: 'This SKU number is already exist!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
               }
            })
            .catch(error => console.log('error', error));
        } else if (this.myObj.productId !== 0 && this.myObj.vendorId !== 0) {
          this.saveButton = true;
          this.addOptions = 'Saving...';
          this.GenObj.product = this.myObj;
          this.GenObj.cakeFlavours = this.flvObj;
          this.GenObj.cakeImages = this.proImg;
          this.GenObj.cakeSizes = this.cakesizeList;
          this.GenObj.cakeLayers = this.cakeLayers;
          console.log(this.GenObj);
          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://okaaik.fastech.pk/api/products/UpdateProduct/',
            headers: {
              Authorization:
                'Bearer pKFBCiJ2J0r5BGU-mlOiSN_IWZF7m2Oxl1yv_PskYmT1udlK7seq3djoVgNfgDLJELFwwxdcYd9LQcvH9OVIpYyZTNrUPDuV9sAzC1WNz2SYEt2TIzJpWmzwAQcPbucnq-ALeFSQc6CsrxFhMacr3bc_KL0Cvl4diQUTvChDZVx4NPxZ-VtMXHiLMzvpBAcrxCQ2rF3V5TpBZFDXDBs1EIonYD9GCG9PHu6U-iIAXKAJ3Xi97-S2kqUd5SwXg0ANLKDqYiWHyF2OJev3_V4g48e9ApFAN4zm7nAPvv1vXZW5H-0vxXBZb1WulF-q5Ljp',
              'Content-Type': 'application/json',
            },
            data: this.GenObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status == 'success') {
                console.log(response.data);
                this.saveButton = false;
                this.addOptions = 'Add Cake';
                this.$router.push('/apps/products/list');
                this.$bvToast.toast('Cake Updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
                // console.log();
              }
              else if (response.data.status == 'exist')
               {
                     this.saveButton = false;
                this.addOptions = 'Update Cake';
              this.$bvToast.toast('Please enter unique SKU number.', {
                  title: 'This SKU number is already exist!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
               }
            })
            .catch(error => console.log('error', error));
        }
      }
    },
    // onSelect() {
    //   const p = this.myObj
    //   this.clickedItems.push(p)
    //   //p=null
    //   console.log(this.clickedItems)
    // },
    //  isValid() {
    //     return this.name.length > 2 ? true : false;
    //   },
    //    isValidSize() {
    //     return this.size.type = number ? true : false;
    //   },
     LoadBySKU() 
     {

        this.checkSKU(); 
        if(this.checkSKU() !== true)
        {
        var axios = require('axios');

        var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/products/GetBySKU/?sku=' + this.myObj.skuNo ,
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        
      }

      axios(config)
        .then(response => {
          //  console.log(JSON.stringify(response.data));
          if(response.data.status == "success")
          {
    
        this.flvObj.cakeFlavourId=[];
        this.flvObj.cakeFlavours=[];
        this.flvObj.pId=0;

        
      
        this.cakeLayers=[];
        this.cakesizeList=[];

          console.log(response.data);
          this.Id = response.data.data;
          
          console.log(this.Id);
              var configs = {
          method: 'get',
          headers: {
            Authorization:
              'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
          },
          url:
            'https://okaaik.fastech.pk/api/products/Getproductbyid/' +
           this.Id ,

        
        }

        axios(configs)
          .then(response => {
            // console.log(JSON.stringify(response.data));
            console.log(response.data.data[0]);
             
            if(this.myObj.productId  == 0)
            {
            console.log("Hi Add wakla");
            this.myObj = response.data.data[0];
            this.myObj.productId = 0;
            this.myObj.coverImg = "";
            this.myObj.vendorId = this.userVendorId;
            this.loading = false;
            }
            else
            {
               console.log("Hi Edit wakla");
          this.myObj.title= response.data.data[0].title;
          this.myObj.price= response.data.data[0].price;
          this.myObj.categoryId= response.data.data[0].categoryId;
          this.myObj.description= response.data.data[0].description;
          this.myObj.minimumTime= response.data.data[0].minimumTime;
          this.myObj.perLayerCost=response.data.data[0].perLayerCost;
          this.myObj.fillingName= response.data.data[0].fillingName;
          this.myObj.totalLayer=response.data.data[0].totalLayer;
          this.myObj.keywords=response.data.data[0].keywords;
          this.myObj.subCategoryId=response.data.data[0].subCategoryId;
          this.loading = "loaded";

            }
                 


            console.log("------------",this.myObj.subCategoryId);
            this.SubCategory(this.myObj.categoryId);
            
            console.log(this.myObj);
           
            console.log(response.data.flavours);
            response.data.flavours.forEach(elem => {
              this.flvObj.pId = elem.productId;
              this.flvObj.cakeFlavourId.push(elem.cakeFlavourId);
              this.flvObj.cakeFlavours.push(elem.flavourId);
            });
            console.log(response.data.cakesize);
            //this.cakesizeList.push(response.data.cakesize);
            console.log('Osama', response.data.cakesize);
            if(response.data.cakelayer.length > 0)
            {
           this.cakeLayers = response.data.cakelayer
              
            }
            response.data.cakesize.forEach(elem => {
              this.cakesizeList.push({
                cakeSizeId: elem.cakeSizesId,
                productId: elem.productId,
                cost: elem.cost,
                size: elem.size,
              });
            });

                if(this.myObj.totalLayer > 1)
                      {
                        this.checkLayers();
                      }
                      this.checktotalLayers();
                      this.checkTitle();
                      this.checkDesc();
                      this.checkCategory();
                      this.checkFlavour();
                      this.checkSizes();
                      this.checkFilling();
                      this.checkMinimumDays();
          // console.log("merilayers", this.cakeLayers);
            // response.data.images.forEach(elem => {
            //   this.proImg.images.push(elem.imagePath);
            //   this.proImg.pId = elem.productId;
            // });
           // console.log('All Data', response.data);
            //this.flvObj.cakeFlavours;
            // this.sizeOptions = response.data;
            // this.dataloading = false;
            // console.log(this.categoryOptions);

            //  this.categoryOptions.Name = response.data[0].Name;
          })
          .catch(function(error) {
            console.log(error);
            //this.dataloading = true;
          });


          }
          else if(response.data.status == "not exist")
          {
            this.$bvToast.toast('Not Found.', {
                  title: 'This SKU number does not exist!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
          }
          
          
          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
         // this.dataloading = true;
        });

        }       
     
     },
    LoadData() {
      this.dataloading = true;

      this.myObj.vendorId = this.userVendorId;
      console.log(this.myObj.vendorId);

      var axios = require('axios');
      var qs = require('qs');

  
      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/categories/getcategories',
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        data: data,
      };

      axios(config)
        .then(response => {
          //  console.log(JSON.stringify(response.data));
          console.log(response.data);
          this.categoryOptions = response.data.query;
          this.subCategoryOptions = response.data.subCategory;
          this.dataloading = false;

          
          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });
      var axios = require('axios');
      var qs = require('qs');
      var data = qs.stringify({});
      var configf = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/flavours/GetAllActive/' +
          this.myObj.vendorId,
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        data: data,
      };

      axios(configf)
        .then(response => {
          // console.log(JSON.stringify(response.data));
          this.flavorOptions = response.data;
          this.dataloading = false;
          console.log('Flavour', response.data);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });
      var configfill = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/fillings/GetAllActive/' +
          this.myObj.vendorId,
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        data: data,
      };

      axios(configfill)
        .then(response => {
          console.log(JSON.stringify(response.data));
          this.fillingOptions = response.data;
          this.dataloading = false;
          console.log(response.data);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });

      var configs = {
        method: 'get',
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        url:
          'https://okaaik.fastech.pk/api/sizes/GetAllForDropdown/' + this.myObj.vendorId,

        data: data,
      };
            var axios = require('axios');
      var qs = require('qs');

      var configkey = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/keywordTemplates/GetAll',
        headers: {
          Authorization:
            'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
        },
        data: data,
      };

      axios(configkey)
        .then(response => {
          //  console.log(JSON.stringify(response.data));
          console.log("--------------------",response.data);
          this.keywordsOptions = response.data;
          this.dataloading = false;

          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });

      axios(configs)
        .then(response => {
          // console.log(JSON.stringify(response.data));
          console.log(response.data);

          this.sizeOptions = response.data.data;
          this.dataloading = false;
          // console.log(this.categoryOptions);

          //  this.categoryOptions.Name = response.data[0].Name;
        })
        .catch(function(error) {
          console.log(error);
          this.dataloading = true;
        });


      if (this.myObj.productId !== 0) {
        this.addOptions = 'Update Cake';
        var configs = {
          method: 'get',
          headers: {
            Authorization:
              'Bearer cVpBcOiMQZKBKHVwXYTOQwPI5gmbZeEodVdYqyd9cGeg90ERPem_PreUozE0oJvN_mW6FHQDSvtiYJZMrMLWjuPzs2tjbdWZ3wWvUXSed8r0ghTERCj5yW7Dumb-pyC1gGDA_j3fPydYQ8kKAF34g-CkqztFC45brtWiwRD5-N_iZNFrdSTwZEF_1hG2Bde4JfoaFcFEK4hfoa3RBe2wFdvBFYhbOan24v-LTB8ypDgvSNkT2BpIDYw5VoYx00lJ-JlWO210G-MHHMmPIKfdTfeUC1mRP2QPNysmNZKtsKe2eJ1l1wj9ThUn6NUEIV9Y',
          },
          url:
            'https://okaaik.fastech.pk/api/products/Getproductbyid/' +
            this.myObj.productId,

          data: data,
        };

        axios(configs)
          .then(response => {
            // console.log(JSON.stringify(response.data));
            console.log(response.data.data[0]);
            this.myObj = response.data.data[0];
            console.log("------------",this.myObj.subCategoryId);
            this.SubCategory(this.myObj.categoryId);
            
            console.log(this.myObj);
            this.loading = 'loaded';
            console.log(response.data.flavours);
            response.data.flavours.forEach(elem => {
              this.flvObj.pId = elem.productId;
              this.flvObj.cakeFlavourId.push(elem.cakeFlavourId);
              this.flvObj.cakeFlavours.push(elem.flavourId);
            });
            console.log(response.data.cakesize);
            //this.cakesizeList.push(response.data.cakesize);
            console.log('Osama', response.data.cakesize);
            if(response.data.cakelayer.length > 0)
            {
           this.cakeLayers = response.data.cakelayer
              
            }
            response.data.cakesize.forEach(elem => {
              this.cakesizeList.push({
                cakeSizeId: elem.cakeSizesId,
                productId: elem.productId,
                cost: elem.cost,
                size: elem.size,
              });
            });
           console.log("merilayers", this.cakeLayers);
            response.data.images.forEach(elem => {
              this.proImg.images.push(elem.imagePath);
              this.proImg.pId = elem.productId;
            });
            console.log('All Data', response.data);
            //this.flvObj.cakeFlavours;
            // this.sizeOptions = response.data;
            // this.dataloading = false;
            // console.log(this.categoryOptions);

            //  this.categoryOptions.Name = response.data[0].Name;
          })
          .catch(function(error) {
            console.log(error);
            //this.dataloading = true;
          });
      }
    },
  },
 
  setup() {
    const priceCard = ref(true);

    const onSubmit = () => {
      store.dispatch('app-product/addMyProduct', productData.value).then(() => {
        //emit('refetch-data')
        $router.push({ name: 'apps-products-list' });
      });
    };

    // const {
    //   priceTableColumns,
    //   refFormObserver,
    //   getValidationState,
    //   resetForm,
    // } = formValidation(resetproductData)

    //  const refInputEl = ref(null)
    // const previewEl = ref(null)

    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      priceCard,
      onSubmit,
      //productData,
      //onSubmit,
      //inputImageRenderer,
      // categoryOptions,
      // flavorOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-product-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.vue-treeselect__control
{
//background-color: #ff5a60;
color: #ff5a60;
}
.vue-treeselect__label.active { font-size:16px !important; color:#555; }

.vue-treeselect__control.sidebar-nav-list-item {
  position: relative;
  display: block;
  font-size: 14px;

  a:hover,
  &.current a {
    color:#ff5a60;
    font-weight: 600;
  }
}
</style>
